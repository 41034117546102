
import $ from "jquery";
import { Tab } from './parts/Tab.js'
import { Accordion } from './parts/Accordion.js'
import { TableScrollLabel } from './parts/TableScrollLabel.js';

window.jQuery = $;
window.$ = $;

// https://github.com/cferdinandi/smooth-scroll
// npm install smooth-scroll
import SmoothScroll from "smooth-scroll"


(function(window, document) {

	class Common {

		/**
		 * メイン
		 * @constructor
		 */
		constructor() {

			// this.fixedSubNav = null;
			// this.isFixedBottomMenu = false;

			this.scroll = null;

			this.init();

		}



		/**
		 * 初期化
		 */
		init = () => {

			$('#menu_btn').on('click', this.toggleMenuMobile);
			$('.accordion').on('click', this.toggleAccordionState);

			this.scroll = new SmoothScroll('a[href*="#"]', {
				header: 'header',
				speed: 300,
				// offset: function (anchor, toggle) {
				// 	return document.querySelector('header').offsetHeight + 40;
				// }
			});

			// this.initFixedBottomSubMenu();

			$(window).on('scroll', this.scrollEvent);
			$(window).on('scrollComplete', this.scrollCompleteEvent);

			this.disableGobalNavi4TouchDevice();

			this.translateButton();

			this.hashAnchorScroll();

		}



		/**
		 * メニュー表示トグル
		 */
		toggleMenuMobile = () => {

			$('#menu_btn').toggleClass('on');
			$('#fixed_main_nav_sp').toggleClass('on');

		}



		/**
		 * アコーディオン表示トグル
		 */
		toggleAccordionState = (e) => {

			let origin = e.currentTarget;
			$(origin).toggleClass('on');
			$(origin).next().toggleClass('on');

		}



		/**
		 * スマホボトムメニューの初期化
		 */
		// initFixedBottomSubMenu = () => {

		// 	this.isFixedBottomMenu = $('#fixed_sub_nav')[0] ? true : false;
		// 	this.fixedSubNav = $('#fixed_sub_nav');

		// 	// console.log('initFixedBottomSubMenu:', isFixedBottomMenu);

		// 	// if(isFixedBottomMenu) {

		// 	// 	$(window).on('scroll', this.scrollEvent);
		// 	// 	$(window).on('scrollComplete', this.scrollCompleteEvent);

		// 	// }
		// 	// else {
		// 	// 	$('footer').addClass('no_pb');
		// 	// }

		// }



		/**
		 * スクロールイベント
		 */
		scrollEvent = () => {
			
			if(this.scrollTimer) {
				clearTimeout(this.scrollTimer);
			}
	
			this.scrollTimer = setTimeout(function() {
				this.scrollTimer = null;
				$(window).trigger('scrollComplete');
			}.bind(this), 500);

			// if(this.isFixedBottomMenu) {
			// 	this.fixedSubNav.addClass('hide');
			// }

		}



		/**
		 * スクロール完了イベント
		 */
		scrollCompleteEvent = (e) => {

			if(window.scrollY > 200) {
				$('header').addClass('thin');
			}
			else {
				$('header').removeClass('thin');
			}

			// if(this.isFixedBottomMenu) {
			// 	this.fixedSubNav.removeClass('hide');
			// }

		}



		/**
		 * タッチデバイスでPCレイアウトグロナビのサブメニューを展開できるようにaタグのイベントを無視させる
		 */
		disableGobalNavi4TouchDevice = (e) => {

			$('#global > ul > li > a').each(function() {

				let subMenu = $(this).siblings('.sub');
				if(subMenu[0]) {
					$(this).on('touchend', function() {
						return false;
					});
				}

			});

		}

		/**
		 * GTranslateの日本語・英語をトグルで切り替えるボタン（スマホ用）
		 */
		translateButton = () => {

			$('#change_translate').on('click', () => {
				$('#lang_menu_sp').toggleClass('on');
			});

			// if($('html').attr('lang') === 'en') {
			// 	$('#change_translate').val('ja|ja'); 
			// } else {
			// 	$('#change_translate').val('ja|en');
			// }
			
		}



		/**
		 * アンカー付きページ遷移の位置対策
		 */
		hashAnchorScroll = () => {

			const hash = location.hash;

			if(hash) {

				window.scrollTo(0, 0);
				setTimeout(() => {
					this.scroll.animateScroll(document.querySelector(hash));
				}, 300);

			}

		}

	}



	new Common();

	// new Accordion($, $('.js-accordion-panel'), $('.js-accordion-panel').find('.question'));
	new Accordion($, $('.accordionList'), $('.accordionList').find('.accordionList__title'));

	new TableScrollLabel($, $('.js-table-wrap'));

})(window, document);
