export class TableScrollLabel {

  /**
   *
   * @param {jQuery} $
   * @param {jQuery Object} targetElement
   * @constructor
   */
  constructor($, targetElement) {
    
    this._$ = $;
    this.targetElement = targetElement;

    this.init();

  }

  init() {
    if(this.targetElement) {
      $(this.targetElement).each(function() {
        $(this).before('<div class="js-table-wrap-scroll-label">横にスライドできます</div>');
      });
    }    
  }

}