export class Accordion {

  /**
   *
   * @param {jQuery} $
   * @param {jQuery Object} element
   * @param {jQuery Object} trigger
   * @constructor
   */
  constructor($, element, trigger) {
    
    this._$ = $;
    this.element = element;
    this.trigger = trigger;

    this.init();

  }

  init() {
    if(0 < this.trigger.length) {
      this.trigger.each(function() {
        $(this).on('click', function(){
          $(this).toggleClass('-open');
          $(this).next().slideToggle();
        });
      });
    }
  }

}