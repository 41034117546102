export class Tab {

  /**
   *
   * @param {jQuery} $
   * @param {jQuery Object} tabLabel
   * @param String tabLabelActiveClassName
   * @param {jQuery Object} tabPanel
   * @param String tabPanelActiveClassName
   * @constructor
   */
  constructor($, tabLabel, tabLabelActiveClassName, tabPanel, tabPanelActiveClassName) {

    this._$ = $;
    this.label = tabLabel;
    this.panel = tabPanel;
    this.activeLabelClassName = tabLabelActiveClassName;
    this.activePanelClassName = tabPanelActiveClassName;

    this.init();

  }

  init() {
    const activeClassLabel = this.activeLabelClassName;
    const activeClassPanel = this.activePanelClassName;
    const tabLabels = $(this.label);
    const tabPanels = $(this.panel);
    if(this.label && this.panel) {
      tabLabels.children().each(function() {
        $(this).css('width', `${100 / $(this).length}%`);
        $(this).on('click', function() {
          $(this).siblings().removeClass(activeClassLabel);
          $(this).addClass(activeClassLabel);
          tabPanels.children().removeClass(activeClassPanel)
          tabPanels.children().eq($(this).index()).addClass(activeClassPanel)
          return false;
        });
      });
    }
  }

}